import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderFooterElements } from '@shared-interfaces/shared-header-footer';
import { HeaderMenuComponent } from './ui/header-menu/header-menu.component';
import { Router } from '@angular/router';
import { HeaderService } from './data-access/header.service';
import { CommonModule } from '@angular/common';
import { AuthService } from 'projects/shared/src/lib/auth/auth/data-access/auth.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoaderService } from '@shared-utils/loader/loader.service';

@Component({
  selector: 'shared-header',
  standalone: true,
  imports: [
    MatToolbarModule,
    HeaderMenuComponent,
    CommonModule,
    MatProgressBarModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  private router = inject(Router);
  private headerService = inject(HeaderService);
  private authService = inject(AuthService);
  private loaderService = inject(LoaderService);

  headerMenuElements$ = this.headerService.headerConfig;
  user$ = this.authService.user;
  apiLoading$ = this.loaderService.loader$;

  @Input() headerMenuElements: HeaderFooterElements | undefined = undefined;

  ngOnInit(): void {
    this.headerService.getMenuItems();
  }

  navigateTo(path: string): void {
    if (path === 'logout') {
      this.authService.logout();
    } else {
      // this.router.navigate([path], {
      //   queryParamsHandling: 'merge',
      // });
    }
  }
}
