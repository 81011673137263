export const environment = {
  production: false,
  local: false,
  firebase: {
    apiKey: 'AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU',
    authDomain: 'dcsptest-sso.service.deloitte.ai',
    projectId: 'dcsptest',
  },
  homeUrl: 'home',
  authParams: ['oidc.azure'],
  signInType: 'signInWithPopup',
  subscriptionServiceBaseURL: 'https://dcsp.api-dev.deloitte.camp',
  subscriptionEndpoint: '/services/subscriptions',
  backendBaseURL: 'https://genai-proto-backend-thw2hllrqq-wn.a.run.app',
  product_id: 'prd_3ecf4aac-274a-4084-beb4-d08241519f71',
  assetTitle: 'genaiproto',
  hideHeader: false,
  hideFooter: false,
};
