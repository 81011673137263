<div class="header-container">
  <mat-toolbar class="header">
    <img class="logo" src="/assets/img/deloitte-logo.svg" />
    <span class="spacer"></span>
    <!-- <shared-header-menu
      [user]="user$()"
      [headerMenuElements]="
        headerMenuElements ? headerMenuElements : headerMenuElements$()
      "
      (headerNavigationEvent)="navigateTo($event)"
    ></shared-header-menu> -->
  </mat-toolbar>
  @if ((apiLoading$ | async)?.includes(true)) {
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  }
</div>
