import { Injectable, inject, signal } from '@angular/core';
import { HeaderFooterElements } from '@shared-interfaces/shared-header-footer';
import { ConfigurationService } from '../../../utils/configuration/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private configService = inject(ConfigurationService);
  headerConfig = signal<HeaderFooterElements | undefined>(undefined);

  getMenuItems(): void {
    this.configService.getConfigItem('/assets/header.json').subscribe({
      next: (headerElements: HeaderFooterElements) => {
        this.headerConfig.set(headerElements);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
