import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loader$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);

  show(): void {
    var load = this.loader$.getValue();
    load.push(true);
    this.loader$.next(load);
  }

  hide(): void {
    var load = this.loader$.getValue();
    load.pop();
    this.loader$.next(load);
  }
}
