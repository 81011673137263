import { Injectable, computed, inject, signal } from '@angular/core';
import { from, defer, BehaviorSubject, Observable } from 'rxjs';
import {
  OAuthProvider,
  User,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { authState } from 'rxfire/auth';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@environments/environment';

import { Router } from '@angular/router';

export type AuthUser = User | null | undefined;

interface AuthState {
  user: AuthUser;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  router = inject(Router);

  private auth = getAuth();

  // sources
  private user$ = authState(this.auth);

  // state
  private state = signal<AuthState>({
    user: undefined,
  });

  // selectors
  user = computed(() => this.state().user);
  isUserSet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    this.user$.pipe(takeUntilDestroyed()).subscribe((user) => {
      this.state.update((state) => ({
        ...state,
        user,
      })),
        user ? this.isUserSet$.next(true) : '';
    });
  }

  login() {
    return from(
      defer(() => {
        const provider = new OAuthProvider(environment.authParams[0]);
        provider.addScope('User.Read');

        if (environment.signInType === 'signInWithRedirect') {
          return signInWithRedirect(this.auth, provider).catch((error: any) =>
            console.error('firebase authentication error', error),
          );
        }

        return signInWithPopup(this.auth, provider).catch((error: any) =>
          console.error('firebase authentication error', error),
        );
      }),
    );
  }

  logout() {
    this.isUserSet$.next(true);
    signOut(this.auth);
    this.router.navigate(['login'], {
      queryParamsHandling: 'merge',
    });
  }
}
